






















































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab1Question4',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        aiQuestionResponse: null,
        language: 'en',
      },
      questions: [
        {
          en: 'A student is monitoring a reaction involving compounds C and D using TLC. Compound C has an Rf value of 0.43; compound D has an Rf of 0.40.',
          fr: 'Un étudiant suit l’avancement d’une réaction par CCM impliquant les composes C et D. Dans le système de solvant utilisé les valeurs Rf sont : Rf(C) = 0.43 ; Rf(D) = 0.40.',
        },
        {
          en: 'a) Draw and upload a picture of the TLC plate at the beginning of the reaction using the upload field below.',
          fr: 'a) Dessinez un schéma de la plaque CCM au début de la réaction en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'b) Draw and upload a picture of the TLC plate after 50% completion (50% of the C molecules have been converted to D molecules) using the upload field below.',
          fr: 'b) Dessinez et téléchargez  un schéma de la plaque CCM lorsque la réaction est à 50% (50% de C est converti en D) en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'c) Draw and upload a picture of the TLC plate at the end of the reaction (all of the C molecules have been converted to D molecules using the upload field below.',
          fr: 'c) Dessinez et téléchargez un schéma de la plaque CCM à la fin de la réaction (Toutes les molécules de C ont été converties en D) en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'd) Why is it important to use a co-spot?',
          fr: 'd) Pourquoi est-il important d’utiliser un point combiné ?',
        },
      ],
      partAImage: [] as File[],
      partBImage: [] as File[],
      partCImage: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.partAImage, ...this.partBImage, ...this.partCImage];
    },
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
